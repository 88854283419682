<template>

<div class="match">

	<div class="match-user" v-tooltip="'Click to view match'" v-on:click="onMoreClick">

		<div class="match-user-image" :style="{backgroundImage: 'url(' + user.avatar + ')'}">

			<app-icon icon="user" v-if="!user.avatar" />

		</div>

		<div class="match-user-about">

			<div class="match-user-about-name">{{ user.name }}</div>
			<div class="match-user-about-bgg" v-if="user.bgg">{{ user.bgg }}</div>

		</div>

		<div class="match-user-actions">

			<app-icon icon="chevron.right" class="match-user-actions-item" />

		</div>

	</div>

	<div class="match-games">

		<div class="match-games-item" v-tooltip="game.name" v-for="game in games" :key="game.id" :style="{backgroundImage: 'url(https://geekgroup.app/api/games/avatar/' + game.id + ')'}" />

		<div class="match-games-item is-more" v-tooltip="'Click to view all'" v-if="gameCount > previewLimit" v-on:click="onMoreClick">

			+{{ gameCount - previewLimit + 1 | prettyNumber }}

		</div>

	</div>

	<div class="match-reason">

		You were matched based on your interest<template v-if="interestCount > 1">s</template> <b>{{ interestNames }}</b>, with <b>{{ gameCount | prettyNumber }}</b> potential game<template v-if="gameCount > 1">s</template> to play.

	</div>

</div>

</template>

<script>

export default {

	props: ['match'],

	data: function() {

		return {
			previewLimit: 10
		}

	},

	computed: {

		user: function() {

			return this.match.user

		},

		games: function() {

			return (this.gameCount > this.previewLimit) ? this.match.games.preview.slice(0, this.previewLimit - 1) : this.match.games.preview

		},

		interestCount: function() {

			return this.match.interests.own.length

		},

		interestNames: function() {

			return this.$_.pluck(this.match.interests.own, 'name').join(', ').replace(/,(?=[^,]*$)/, ' and')

		},

		gameCount: function() {

			return this.match.games.total

		}

	},

	methods: {

		onMoreClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Match',
				params: {
					id: this.match.user.id
				}
			})

		}

	}

}

</script>

<style scoped>

.match {
	border-radius: 12px;
	margin-right: 8px;
	margin-bottom: 8px;
	border: 1px solid #eee;
	background-color: #fff;
	flex-direction: column;
	display: flex;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.is-desktop .match:nth-child(3n) {
	margin-right: 0px;
}

.is-tablet .match {
	width: calc(50% - 4px);
}

.is-tablet .match:nth-child(2n) {
	margin-right: 0px;
}

.is-mobile .match {
	width: 100%;
	margin-right: 0px;
}

.match-user {
	display: flex;
	padding: 10px;
	align-items: center;
	cursor: pointer;
}

.match-user-image {
	height: 48px;
	width: 48px;
	background-size: cover;
	border-radius: 12px;
	background-position: 50% 50%;
	border: 1px solid #eee;
	font-size: 24px;
	text-align: center;
	color: #ddd;
	line-height: 48px;
	flex-shrink: 0;
}

.match-user-about {
	padding-left: 10px;
	flex-grow: 1;
}

.match-user-actions {
	flex-shrink: 0;
}

.match-user-actions-item {
	height: 48px;
	line-height: 48px;
	font-size: 16px;
	padding: 0px 10px;
	color: #333;
	text-align: center;
}

.match-user-about-name {
	color: #333;
	font-weight: 400;
	font-size: 16px;
}

.match-user-about-bgg {
	color: #707070;
	font-size: 14px;
	margin-top: 2px;
}

.match-reason {
	padding: 10px;
	color: #333;
	font-size: 12px;
	line-height: 16px;
	border-top: 1px solid #eee;
}

.match-games {
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid #eee;
	padding: 8px;
}

.match-games-item {
	width: calc(20% - 4px);
	margin: 2px;
	background-color: #eee;
	background-size: cover;
	background-position: 50% 50%;
	border-radius: 4px;
}

.match-games-item:before {
	content: "";
	display: block;
	padding-top: 100%;
}

.match-games-item.is-more {
	background-color: #4082d3;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	cursor: pointer;
}

</style>
